import { createContext, useContext, useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  onAuthStateChanged,
  signOut,
  RecaptchaVerifier, setPersistence, browserLocalPersistence
} from "firebase/auth";
import app, { auth } from "../firebase";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [APIdata, setAPIdata] = useState('')
  const [CurrentPickedCan, setCurrentPickedCan] = useState("")


  const dataAPI = (obj) => {
    setAPIdata(obj)
  }


  const ThisCanditate = (obj) => {
    setCurrentPickedCan(obj)
  }



  const signIn = async (email, password) => {
    try {
      // Set Firebase Authentication to use "local" persistence
      await setPersistence(auth, browserLocalPersistence);
      
      // Now, sign in with the specified email and password and set expiration to 30 days
      const userCredential = await signInWithEmailAndPassword(auth, email, password, {
        expiresIn: "30d", // Set the duration (30 days in this example)
      });
      
      // Return the user credential or user information if needed
      return userCredential;
    } catch (error) {
      // Handle any errors here
      console.error("Error setting persistence or signing in:", error);
      throw error; // Optionally rethrow the error for further handling in your components
    }
  };

  
  const logout = () => {
    localStorage.clear();

    return signOut(auth);
  };

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log(`captcha response: ${response}`)
      }
    }, auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ signIn, logout, user, APIdata, dataAPI, CurrentPickedCan, ThisCanditate}}>{children}</UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
