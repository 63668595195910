import React from 'react'

import { LoginForm } from '../components'
import AnimatedPage from "../utils/AnimatedPage";
import Logo from "../assets/images/logo.svg";

const Login = () => {
  return (
    <AnimatedPage>
      <img className="mx-auto h-12 w-auto" src={Logo} alt="logo" />
      <LoginForm/>
    </AnimatedPage>
  )
}

export default Login