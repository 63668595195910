export const optionmedia = [
    'LinkedIn', 'Facebook', 'Jobindex', "Stepstone", "Other", "CompassHRG.com"
  ];
  
export const SpecialistLeaderExecutive = [
    'Specialist', 'Leader ', "Executive"
  ];


export const optionsWillingnesstomove = [
    "To a very low degree", "To a low degree", "Either/or", "To a high degree", "To a very high degree"
  ]
  
  
export const optionsIndustry = [
    "Consumer Products", 
    "Electronics", 
    "Financial services", 
    "Goverment, education and non-profit organisations", 
    "Industrial", 
    "Information and communication",
    "IT/Technology",
    "Life Science and Health Care",
    "Proffesional service",
    "Real estate",
    "Unspecified"
  ]
  
  
export const OptionHoursperworkweek = [
    "30 hours per week", "37 hours per week", "38-45 hours per week", "46-52 hours per week", "53-59 hours per week", "60 hours or more per week"
  ]
  
export const OptionHybridWork = [
    "0 days working from home per week", "1 day working from home per week", "2 days working from home per week", "3 days working from home per week", "4 days working from home per week", "5 days working from home per week"
  ]
  
  
export const optionHigherpositionorsameposition = [
    "Higher level than today",
    "Same level as today",
    "Lower level than today"
  ]
  
export const optionDepartment = [
    "Production",
    "Research and Development",
    "Purchasing",
    "Sales",
    "Marketing",
    "Human Resource Management",
    "Accounting and Finance",
    "Distribution",
    "Other"
  ]
  
export const optionResignationwarning = [
    "1 month",
    "2 months",
    "3 months",
    "4 months",
    "5 months",
    "6 months",
    "7 months or more"
  
  ]
  

  export const GdprConsent = [
    "Approved",
    "Not Yet Informed",
    "Consent Requested"
  ]

  export const stages = [
    "1. Target Candidate",
    "2. Application Received",
    "3. Left Message",
    "4. In Discussion",
    "5. Consultant Interview",
    "6. Shortlisted",
    "7. Submitted to Client",
    "8. Client Interview",
    "9. Offer",
    "10. Placed",
    "X. Candidate on hold",
    "X. Rejected by Candidate",
    "X. Rejected by Consultant",
    "X. Not Relevant",
    "X. Rejected by Client"
  ]
  
  export const CurrentCurrency = [
    "DKK",
    "EUR",
    "NOK",
    "SEK",
    "CHF",
    "GBP",
    "USD",
  ];

  export const NoticePeriods = [
    "Immediate",
    "1 Week",
    "2 Weeks",
    "3 Weeks",
    "4 Weeks",
    "5 Weeks",
    "6 Weeks",
    "1 Month",
    "2 Months",
    "3 Months",
    "4 Months",
    "5 Months",
    "6 Months",
  ]