import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

class MyDropdownlist extends Component {
  constructor(props) {
    super(props);
    const { defaultOption, options, formDataValue } = this.props;
    const selectedOption = defaultOption
      ? { value: defaultOption, label: defaultOption }
      : { value: formDataValue, label: formDataValue };
    const selectOptions = options.map(option => ({ value: option, label: option }));
    this.state = {
      selectedOption,
      selectOptions,
    };
  }

  handleChange = (selectedOption) => {
    const { name, onChange } = this.props;
    this.setState({ selectedOption });
    if (onChange) {
      onChange(selectedOption, name);
    }
  }

  render() {
    const { selectedOption, selectOptions, } = this.state;
    return (
      <Select
        className='input-result'
        options={selectOptions}
        components={animatedComponents}
        value={selectedOption}
        onChange={this.handleChange}
      />
    );
  }
}

export default MyDropdownlist;