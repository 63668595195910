import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import GdprGreen from '../assets/images/GDPR-Green.png';
import GdprYellow from '../assets/images/GDPR-Yellow.png';
import GdprRed from '../assets/images/GDPR-Red.png';

const animatedComponents = makeAnimated();

class MyDropdownlistInfo extends Component {
  constructor(props) {
    super(props);
    const defaultOption = { value: this.props.defaultOption, label: this.props.defaultOption };
    const defaultText = { value: this.props.defaultText, label: this.props.defaultText };
    const options = this.props.options.map(option => ({ value: option, label: option }));
    this.state = {
      selectedOption: defaultOption,
      options,
      selectTextOption: defaultText,
      showDropdown: false // New state to track dropdown visibility
    };
  }

  handleMouseEnter = () => {
    this.setState({ showDropdown: true });
  };

  handleMouseLeave = () => {
    this.setState({ showDropdown: false });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  render() {
    const { selectedOption, options, showDropdown, selectTextOption } = this.state;

    // Get the path to the image based on the selected option value
    const getImagePath = (optionValue) => {
      if (optionValue === 'Approved') {
        return GdprGreen; // Path to the green image
      } else if (optionValue === 'Informed'  || optionValue === 'NotInformed' ) {
        return GdprYellow; // Path to the yellow image
      } else if (optionValue === 'Expired') {
        return GdprRed; // Path to the red image
      }
      else if (optionValue === 'Requested') {
        return GdprYellow; // Path to the red image
      }
      return ''; // Return empty string if no matching value
    };

    const getClassName = (optionValue) => {
      if (optionValue === 'Approved') {
        return 'GdprGreen';
      } else if (optionValue === 'Informed'  || optionValue === 'NotInformed' ) {
        return 'GdprYellow';
      } else if (optionValue === 'Expired') {
        return 'GdprRed';
      }
      else if (optionValue === 'Requested') {
        return 'GdprYellow';
      }
      return ''; // Return empty string if no matching value
    };

    // Custom option component
    const CustomOption = ({ innerProps, label, data }) => {
      const imagePath = getImagePath(data.value);
      return (
        <div {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
          <span className="icon">
            <img src={imagePath} className="mx-2" width={15} alt="Logo" />
          </span>
          <span className="label">{label}</span>
        </div>
      );
    };

    // Custom single value component
    const CustomSingleValue = ({ children }) => (
      <div
        className="single-value"
        style={{
          gridArea: '1/1/2/3',
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: 'hsl(0, 0%, 20%)',
          marginLeft: '1px',
          marginRight: '1px',
          boxSizing: 'border-box',
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span className="icon icon-info">
          <img src={getImagePath(selectedOption.value)} className={`mx-1`} width={15} alt="Logo" />
        </span>
        <span className="label text-sm">{children}</span>
      </div>
    );

    const customStyles = {};

    return (
      <div
        className="w-full h-auto relative"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Select
          className="input-result"
          options={options}
          components={{ ...animatedComponents, Option: CustomOption, SingleValue: CustomSingleValue,  }}
          value={selectedOption}
          onChange={this.handleChange}
          isSearchable={false}
          isDisabled={true} 
        />
        {showDropdown && (
          <div className="bg-gray-200 mt-0.5 h-auto w-80 border-2 border-gray-300 rounded-md absolute z-10 dropdown-info">
            <div className="grid-cols-12 py-4 px-4 text-xs">
              <div className="col-span-12">
                <p className="text-gray-600">Purpose(s)</p>
              </div>
              <div className="col-span-12 my-2">
                <p className="text-black font-bold">Recruitment & Executive Search</p>
                <p className="text-black font-bold">Recruitment Database</p>
              </div>
              <div className="col-span-12">
                <p className="text-gray-600">Lawful Basis</p>
              </div>
              <div className="col-span-12 my-2">
                <p className="text-black font-bold">Recruitment & Executive Search</p>
              </div>
              <div className="col-span-12">
                <label className={`buttonlookalike ${getClassName(selectedOption.value)}`}>
                {selectTextOption.value}
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MyDropdownlistInfo;