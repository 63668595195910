import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { AuthContextProvider } from "./contexts/AuthContext";
import { Provider } from "./contexts/FetchUserInformation";
import { Search, Login, Results, PeopleResults, WelcomePage, SearchResults, Candidate} from "./pages";
import { ProtectedRoutes, NoMatch } from "./components";


import React from 'react';
import './content/stylesheet/main.css';

function App() {

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col py-12 sm:px-6">
      <ToastContainer />
      <AuthContextProvider>
        <Provider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/search" element={<ProtectedRoutes><Search /></ProtectedRoutes>} />
            <Route path="/results" element={<ProtectedRoutes><Results /></ProtectedRoutes>} />
            <Route path="/peopleResults" element={<ProtectedRoutes><PeopleResults /></ProtectedRoutes>} />
            <Route path="/searchResults" element={<ProtectedRoutes><SearchResults /></ProtectedRoutes>} />
            <Route path="/canditate" element={<ProtectedRoutes><Candidate /></ProtectedRoutes>} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Router>
        </Provider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
