import React from 'react'

import { ResultsForm, UserInfo } from '../components'
import { UserAuth } from '../contexts/AuthContext'
import AnimatedPage from "../utils/AnimatedPage";
import Logo from "../assets/images/logo.svg";
import PeopleResultsForm from '../components/PeopleResultsForm';

const PeopleResults = () => {
  const { user } = UserAuth()

  return (
    <AnimatedPage>
      <img className="mx-auto h-12 w-auto mb-10" src={Logo} alt="logo" />
      {/* {user ? <UserInfo/> : null} */}
      <PeopleResultsForm/>
    </AnimatedPage>
  )
}

export default PeopleResults;