import React, { useState, useEffect } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { ScrollMode, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import FileViewer from "react-file-viewer";
import Pdf from "../../assets/pdf/NoPDF.pdf";
import useFetchUserContext from "../../hooks/use-userInformation-context";

function DocumentViewer({ DocumentInfo }) {
  const {
    currentUserID,
    currentDocument,
    fetchSingleInveniasDocument,
    defaultLookUpInveniasFiles,
  } = useFetchUserContext();

  useEffect(() => {
    defaultLookUpInveniasFiles(currentUserID);
  }, [currentUserID]);

  const Docmenntholder = () => {
    return <div>This is the content of Docmenntholder</div>;
  }
  

  /*
  useEffect(() => {
    console.log(DocumentInfo[1], DocumentInfo[0]);
    fetchSingleInveniasDocument(
      currentUserID,
      DocumentInfo[1],
      DocumentInfo[0]
    );
  }, [currentUserID]);
  */

const DocumentRenderRule = () => {

    return (<FileViewer
      fileType="docx"
      filePath={currentDocument}
      className="file-viewer-style"
    />)
}

  

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      fullScreenPlugin: {
        onEnterFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageFit);
          defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
            ScrollMode.Wrapped
          );
        },
        onExitFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageWidth);
          defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
            ScrollMode.Vertical
          );
        },
      },
    },
  });

  return (
    <div className="pdf-container">
      {currentDocument.endsWith(".pdf") ? (
        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.15.349/pdf.worker.min.js">
          <div className="inner-pdf-container">
            <Viewer
              fileUrl={currentDocument}
              plugins={[defaultLayoutPluginInstance]}
              defaultScale={SpecialZoomLevel.PageFit}
            />
          </div>
        </Worker>
      ) : (
        <DocumentRenderRule/>
      )}
    </div>
  );
}

export default DocumentViewer;
