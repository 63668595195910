import React, { useState, useRef } from "react";
import { FaFileDownload, FaMinus, FaPlus } from "react-icons/fa";
import { renderToString } from "react-dom/server";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function ResultTab({ onToggle, PrintFormData }) {
  const [toggle, setToggle] = useState(true);

  const OnToggleClick = () => {
    setToggle((prevValue) => !prevValue);
    onToggle(!toggle);
  };

  const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
  };
  const colstyle = {
    width: "30%"
  };
  const tableStyle = {
    width: "100%"
  };
  const Prints = () => (
    <div className="w-full h-auto">
      <div style={{height: "auto", width: "100%", background: "Green"}}>
      <h3>Form Data: {PrintFormData.NewCanditateID} </h3>
      </div>
    </div>
  );

  const DownloadPdf = () => {
    console.log(PrintFormData.NewCanditateID)
    const string = renderToString(<Prints />);
    const pdf = new jsPDF("p", "mm", "a4");
    const columns = [
      "SOW Creation Date",
      "SOW Start Date",
      "Project",
      "Last Updated",
      "SOW End Date"
    ];
    var rows = [
      [
        "Dec 13, 2017",
        "Jan 1, 2018",
        "ABC Connect - ABCXYZ",
        "Dec 13, 2017",
        "Dec 31, 2018"
      ]
    ];
    pdf.fromHTML(string);
    pdf.save("pdf");

  };

  return (
    <div className="col-span-4 text-right p-4">
      <button onClick={DownloadPdf} className="btn">
        <FaFileDownload className="text-gray-100 text-2xl p-1 bg-blue-500 rounded-full border-blue-800 border-solid ml-2" />
      </button>
      {toggle ? (
        <button onClick={OnToggleClick} className="btn">
          <FaMinus className="text-gray-100 text-2xl p-1 bg-red-500 rounded-full border-Red-800 border-solid ml-2" />
        </button>
      ) : (
        <button onClick={OnToggleClick} className="btn">
          <FaPlus className="text-gray-100 text-2xl p-1 bg-green-500 rounded-full border-green-800 border-solid ml-2" />
        </button>
      )}
    </div>
  );
}

export default ResultTab;