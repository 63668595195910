import React, { useState, useEffect } from 'react'
import { useNavigate, useHistory } from 'react-router-dom'
import axios from 'axios'
import { UserAuth } from '../contexts/AuthContext'
import Autocomplete from '../components/Autocomplete'
import AutoComplete from './__tests__/AutoComplete'
import { toast } from 'react-toastify';

const FetchForm = () => {
  const navigate = useNavigate()
  const [value, setValue] = useState("Email")
  const [userValue, setuserValue] = useState('')
  const {dataAPI, logout } = UserAuth()
  const [selectedValue, setSelectedValue] = useState("1");
  const [dataSearch, setdataSearch] = useState([]);

  const [inputValue, setInputValue] = useState('');
  const [dropdownValue, setDropdownValue] = useState(1);

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleDropdownChange = (value) => {
    setDropdownValue(value);
  };
  // const [APIdata, setAPIdata] = useState('')

  const handleLogout = async () => {
    try {
      
      await logout();
      navigate('/');
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    console.log(event.target.value);
  }

  const onChangeHandler = (e) => {
    setValue(e.target.value)
  }

  const onValueChange = (e) => {
    setuserValue(e.target.value)
  }


  const fetchEmail = async () => {
    const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchOne?email=${inputValue}`)
    const data = response.data
    dataAPI(data)

  };


  const CheckEmail = async () => {
    const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchOne?email=${inputValue}`)
    const data = response.data;
    return data;
  };
  
  


  const FetchPhoneNumber = async () => {
    // e.preventDefault();
    const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchOneByNumber?phone_number=${inputValue}`)
    const data = response.data
    dataAPI(data)
  };

  const CheckPhoneNumber = async () => {
    // e.preventDefault();
    const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchOneByNumber?phone_number=${inputValue}`)
    const data = response.data
    return data;
  };

  const FetchFullName = async () => {
    // e.preventDefault();
    
    
    const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchByFullName?full_name=${inputValue}`)
    const data = response.data
    
    if(data.length !== 0){
      dataAPI(data)
    }else{
      dataAPI([])
    }
    
  }

  const FetchByAssignment = async () => {
    // e.preventDefault();
    
    const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/FetchByAssignment?assignment_name=${inputValue}`)
    const data = response.data
    if(data.length !== 0){
      dataAPI(data)
    }else{
      dataAPI([])
    }
    
  }


  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function validatePhoneNumber(phoneNumber) {
    const phoneRegex = /^[0-9]{8,16}$/;
    return phoneRegex.test(phoneNumber);
  }

  function validateFullName(fullName) {
    const words = fullName.split(' ');
    return words.length >= 1;
  }

  const handleSearch = (e) => {
    e.preventDefault();
    
    if(dropdownValue === 2) {
      if (validateEmail(inputValue)) {
          fetchEmail().then(()=>{navigate('/results');})
      } 
    }
  
    else if(dropdownValue === 3){
      if (validatePhoneNumber(inputValue)) {
          FetchPhoneNumber().then(()=>{navigate('/results');})
      } else {
        toast.error('PHONENUMBER IS NOT VALID', { theme: "colored", hideProgressBar: true });
      }
    ;}

    else if(dropdownValue === 1){
      if (validateFullName(inputValue)) { 
        
         FetchFullName().then(()=>{navigate('/PeopleResults');})
      }
    }

    else if(dropdownValue === 4){
      FetchByAssignment().then(()=>{navigate('/PeopleResults');})
    }
}

useEffect(() => {
  console.log("DRWAAA")
  fetch('https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchAll') // Replace with the endpoint that returns the user data
    .then(response => response.json())
    .then(users => {
      const userRows = users.map(user => [
        { name: 'First Name', value: user.first_name },
        { name: 'Last Name', value: user.last_name },
        { name: 'Email', value: user.email },
        { name: 'Phone Number', value: user.phone_number }
      ]);
      setdataSearch(userRows);
    })
    .catch(error => console.error(error));
}, []);

  return (
    <div className='flex flex-col max-w-xl mx-auto'>
      <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10">
        <h2 className="mb-6 text-center text-2xl text-gray-900 font-link font-semibold">Search for <span className='text-[#1EADE1] text-3xl font-bold'>Candidate</span></h2>
        <form className="mb-0 space-y-6 mt-12">
          <div>
              <Autocomplete onValueChange={handleInputChange} onDropdownChange={handleDropdownChange} inputValue={inputValue} dropdownValue={dropdownValue} onSearch={handleSearch} />
              {/*<AutoComplete />*/}
          </div>
        </form>

        <div  className='flex justify-center'>
            <button type="submit" onClick={handleLogout} className="w-1/4 float-right mt-5 mr-5  py-2 px-2 rounded-md shadow-sm text-sm font-medium text-white bg-[#F51E1E] hover:bg-[#F50501]">Logout</button>
          </div>
      </div>
    </div>
  )
}

export default FetchForm;