import React, { useState, useEffect } from "react";
//import { toast } from 'react-toastify';

import { useNavigate } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";
import useFetchUserContext from "../hooks/use-userInformation-context";
import axios from "axios";
import { FaPlus } from "react-icons/fa";
import { PeoplePagination } from "./people-pagination.js";
import { render } from "@testing-library/react";

const PeopleResultsForm = (props) => {
  
  const navigate = useNavigate();

  const { CurrentUser } = useFetchUserContext();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (e) {
      console.log(e.message);
    }
  };

  const Handleback = async () => {
    navigate("/search");
  };

  const { dataAPI, APIdata, logout, ThisCanditate } = UserAuth();

  const [searchTerm, setSearchTerm] = useState("");
  const [displayData, setDisplayData] = useState([]);

  /* pagination */

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState(Math.ceil(APIdata.length / itemsPerPage));

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayedItems = APIdata.sort(function (a, b) {
    if (a.first_name < b.first_name) {
      return -1;
    }
    if (a.first_name > b.first_name) {
      return 1;
    }
    return 0;
  }).slice(startIndex, endIndex);
  
  /* end of pagination code */

  /* find if page is assignment or rest */

  
  /**
   * const isAssignmentSearch = APIdata[0].assignments.progress_status

  console.log(isAssignmentSearch)
   */

  let assignmentMap = APIdata.flatMap((user) => {return user.assignments}) 
  //let assignmentSearch = assignmentMap.every( (val, i, arr) => val.external_ref === arr[0] )
  
  

  useEffect(() => {
    console.log(displayedItems)

    const RemapApi = displayedItems.flatMap((user) => {
      if (user.assignments) {
        return user.assignments.map((assignment) => ({
          value: `${user.first_name} ${user.last_name}`,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          jobtitle: user.assignment_name,
          applied_company: user.current_latest_employer,
          phone_number: user.phone_number,
          assignment: assignment,
          progress: assignment.progress_status,
          id: user.id,
        }));
      }

      return {
        value: `${user.first_name} ${user.last_name}`,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        jobtitle: user.assignment_name,
        applied_company: user.current_latest_employer,
        phone_number: user.phone_number,
        assignment: {
          assignment_name: user.assignment_name,
          candidate_internal_comments: user.candidate_internal_comments,
          external_ref: user.external_ref,
          job_level: user.job_level,
          notes: user.notes,
          progress_notes: user.progress_notes,
          progress_status: user.progress_status,
        },
        progress: user.progress_status,
        id: user.id,
      };
    });

    setDisplayData(RemapApi);

  }, [currentPage]);

  useEffect(() => {

    const RemapApi = APIdata.flatMap((user) => {
      if (user.assignments) {
        
        return user.assignments.map((assignment) => ({
          value: `${user.first_name} ${user.last_name}`,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          jobtitle: user.assignment_name,
          applied_company: user.current_latest_employer,
          phone_number: user.phone_number,
          assignment: assignment,
          progress: assignment.progress_status,
          id: user.id,
        }));
      }

      return {
        
        value: `${user.first_name} ${user.last_name}`,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        jobtitle: user.assignment_name,
        applied_company: user.current_latest_employer,
        phone_number: user.phone_number,
        assignment: {
          assignment_name: user.assignment_name,
          candidate_internal_comments: user.candidate_internal_comments,
          external_ref: user.external_ref,
          job_level: user.job_level,
          notes: user.notes,
          progress_notes: user.progress_notes,
          progress_status: user.progress_status,
        },
        progress: user.progress_status,
        id: user.id,
      };
    });

    const filteredData = RemapApi.filter(
      (person) =>
        assignmentMap[0] === undefined ?
        (person.assignment.progress_status.toLowerCase().includes("5. consultant interview") ||
        person.assignment.progress_status.toLowerCase().includes("6. shortlisted") ||
        person.assignment.progress_status.toLowerCase().includes("8. client interview")) 
        &&
        (person.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        person.assignment.external_ref.includes(searchTerm) ||
        person.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        person.phone_number?.includes(searchTerm))
        :
        
        (person.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        person.assignment.external_ref.includes(searchTerm) ||
        person.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        person.phone_number?.includes(searchTerm))
    )


    setDisplayData(filteredData.slice(startIndex, endIndex))

    setTotalPages(Math.ceil(filteredData.length / itemsPerPage))

    setCurrentPage(1)
    

  }, [searchTerm]);

  const handleChange = (e) => {
    const searchTerm = e.target.value;

    setSearchTerm(searchTerm)

  };

  const handlePaginationChange = (e) => {
    let index = e.target.getAttribute("index");
    

    if( index <= 0 || index > totalPages){
      return
    }

    setCurrentPage(parseInt(index));
  };

  const fetchID = async (value) => {
    try {
      const response = await axios.get(
        `https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchFromID?id=${value}`
      );
      const data = response.data;
      dataAPI(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (
    email,
    external_ref,
    progress_notes,
    consent_status,
    assignment_name,
    candidate_internal_comments,
    interview,
    screening,
    job_level,
    id
  ) => {
    ThisCanditate({
      external_ref: external_ref,
      progress_notes: progress_notes,
      consent_status: consent_status,
      assignment_name: assignment_name,
      candidate_internal_comments: candidate_internal_comments,
      interview: interview,
      screening: screening,
      job_level: job_level,
    });
    CurrentUser(id);
    fetchID(id).then(() => {
      navigate("/results");
    });
  };

  const CreateCandidateOnCLick = () => {
    navigate("/canditate");
  };

  return (
    <div className="flex flex-col max-w-6xl min-w-4x1 mx-auto PeopleResults-container pb-4">
      <div className=" bg-white rounded-lg shadow-md">
        <div className="grid grid-cols-12 bg-gray-50 py-4 px-4 border-gray-100">
          <div className="col-span-9">
            <label
              for="default-search"
              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  class="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                value={searchTerm}
                onChange={handleChange}
                id="default-search"
                class="block w-full py-4 px-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Filter Candidates..."
                required
              />
            </div>
          </div>
          <div className="flex justify-end col-span-3 items-end pl-2">
            {" "}
            {/* Added "flex justify-end" */}
            <button
              onClick={CreateCandidateOnCLick}
              className="bg-green-400 hover:bg-emerald-400 transition text-white py-3.5 px-4 rounded inline-flex items-center w-full justify-center"
            >
              <FaPlus className="fill-current w-4 h-4 mr-2" />
              <span>Create Candidate</span>
            </button>
          </div>
        </div>

        {displayData.length !== 0 ? (
          <div className=" bg-white rounded-lg shadow-md">
            <table className="table-auto w-full PeopleResults">
              <thead className="text-gray-700 bg-[#1EADE1] text-white">
                <tr>
                  <th className="px-4 py-1 text-xs font-bold">Name</th>
                  <th className="px-4 py-1 text-xs">Assignments</th>
                  <th className="px-4 py-1 text-xs">Job</th>
                  <th className="px-4 py-1 text-xs">Company</th>
                  <th className="px-4 py-1 text-xs">Progress</th>
                  <th className="px-4 py-1 text-xs">Email</th>
                  <th className="px-4 py-1 text-xs">Phonenumber</th>
                  <th className="px-4 py-1"></th>
                </tr>
              </thead>
              <tbody>
                {displayData.map((person, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-gray-50" : ""}
                  >
                    <td className="text-xxs">{person.name}</td>
                    <td className="text-xxs">
                      {person.assignment.external_ref}
                    </td>
                    <td className="text-xxs">
                      {person.assignment.assignment_name}
                    </td>
                    <td className="text-xxs">{person.applied_company}</td>
                    <td className="text-xxs">
                      {person.assignment.progress_status ===
                        "1. Target Candidate" ||
                      person.assignment.progress_status ===
                        "2. Application Received" ||
                      person.assignment.progress_status === "3. Left Message" ||
                      person.assignment.progress_status ===
                        "4. In Discussion" ||
                      person.assignment.progress_status ===
                        "5. Consultant Interview" ||
                      person.assignment.progress_status === "6. Shortlisted" ||
                      person.assignment.progress_status ===
                        "7. Submitted to Client" ||
                      person.assignment.progress_status ===
                        "8. Client Interview" ||
                      person.assignment.progress_status === "9. Offer" ||
                      person.assignment.progress_status === "10. Placed" ? (
                        <div className="btn btn-primary py-0.5 px-1 rounded-md shadow-sm font-medium text-white bg-green-600 w-full text-center font-xxs">
                          Active
                        </div>
                      ) : person.assignment.progress_status ===
                          "X. Rejected by Candidate" ||
                        person.assignment.progress_status ===
                          "X. Rejected by Consultant" ||
                        person.assignment.progress_status ===
                          "X. Not Relevant" ||
                        person.assignment.progress_status ===
                          "X. Rejected by Client" ? (
                        <div className="btn btn-primary py-1 px-2 rounded-md shadow-sm font-medium text-white bg-[#dc1f1f] w-full text-center font-xxs">
                          Inactive
                        </div>
                      ) : person.assignment.progress_status ===
                        "X. Candidate on hold" ? (
                        <div className="btn btn-primary py-1 px-2 rounded-md shadow-sm font-medium text-white bg-[#ffdd1f] w-full text-center">
                          On Hold
                        </div>
                      ) : person.assignment.progress_status === null ? (
                        <div>None</div>
                      ) : null}
                    </td>
                    <td className="text-xxs">{person.email}</td>
                    <td className="text-xxs">{person.phone_number}</td>
                    <td>
                      <button
                        onClick={() =>
                          handleClick(
                            person.email,
                            person.assignment.external_ref,
                            person.assignment.progress_notes,
                            person.assignment.progress_status,
                            person.assignment.assignment_name,
                            person.assignment.candidate_internal_comments,
                            person.assignment.notes?.description?.interview,
                            person.assignment.notes?.description?.screening,
                            person.assignment.job_level,
                            person.id
                          )
                        }
                        className="btn btn-primary py-2 px-4 rounded-md shadow-sm text-xs font-medium text-white bg-[#68FA6F] hover:bg-[#44FA57]"
                      >
                        Screen
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PeoplePagination
              currentPageIndex={currentPage}
              changePageIndex={handlePaginationChange}
              totalPages={totalPages}
                      />

          </div>
        ) : (
          <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
              <div className="mx-auto max-w-screen-sm text-center">
                <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                  User Not Found in the Database or has No Assignments
                </p>
                <p className="mb-4 text-md font-light text-gray-500 dark:text-gray-400">
                  The user cannot be found in the database or has no
                  Assignments. Would you like to create a new user?
                </p>
                <button
                  onClick={CreateCandidateOnCLick}
                  className="bg-green-400 hover:bg-emerald-400 transition p-3 rounded-3xl"
                >
                  <FaPlus className="text-white text-lg" />
                </button>
              </div>
            </div>
          </section>
        )}
      </div>
      <div className="items-right">
        <button
          onClick={Handleback}
          className="w-1/4 float-right mt-5 mr-5  py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white  bg-[#1EADE1] hover:bg-[#1FAEF9]"
        >
          Back
        </button>
        <button
          type="submit"
          onClick={handleLogout}
          className="w-1/4 float-right mt-5 mr-5  py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white bg-[#F51E1E] hover:bg-[#F50501]"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default PeopleResultsForm;
