import React from 'react'
import { UserAuth } from '../contexts/AuthContext'
import AnimatedPage from "../utils/AnimatedPage";
import Logo from "../assets/images/logo.svg";
import CandidateForm from '../components/CandidateForm';

const Candidate = () => {
    const { user } = UserAuth()
  
    return (
      <AnimatedPage>
        <img className="mx-auto h-12 w-auto mb-10" src={Logo} alt="logo" />
        {/* {user ? <UserInfo/> : null} */}
        <CandidateForm/>
      </AnimatedPage>
    )
  }
  
export default Candidate;