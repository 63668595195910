import React, { useState, useEffect} from "react";

import { toast } from 'react-toastify';
import 'tippy.js/dist/tippy.css';
import axios from "axios";
import { Form, useNavigate } from "react-router-dom";

import {FaPlus} from "react-icons/fa";
import useFetchUserContext from "../hooks/use-userInformation-context";
import { UserAuth } from '../contexts/AuthContext';


function CandidateForm(){

    const {CreateCandidate} = useFetchUserContext();
    const {logout} = UserAuth();
    const navigate = useNavigate();

    const [createUser, setCreateUser] = useState({ 
        Email: "",
        FirstName: "", 
        LastName: "",
        PhoneNumber:"",
        JobTitle: "",
        CompanyName: "",
        Origin: "C_Pass",
    })

    const [fieldValidity, setFieldValidity] = useState({
        Email: true,
        FirstName: true,
        LastName: true,
        PhoneNumber: true,
        JobTitle: true,
        CompanyName: true,
    });


    const handleChange = (e) => {
        setCreateUser((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        setFieldValidity((prevState) => ({ ...prevState, [e.target.name]: e.target.value.trim() !== "" }));
    }

    const HandleClick = async (e) => {
        e.preventDefault();
      
        if (
          createUser?.Email !== "" &&
          createUser?.FirstName !== "" &&
          createUser?.LastName !== "" &&
          createUser?.PhoneNumber !== "" &&
          createUser?.JobTitle !== "" &&
          createUser?.CompanyName !== ""
        ) {
          try {
            toast.dismiss(); // Dismiss any existing toasts
      
            toast.loading('Creating...', {
              theme: 'colored',
              hideProgressBar: true,
            });
      
            // Assuming CreateCandidate is an asynchronous function
            await CreateCandidate(createUser);
      
            toast.dismiss(); // Dismiss the loading toast
            toast.success('Created', {
              theme: 'colored',
              hideProgressBar: true,
              autoClose: 2000,
            });
          } catch (error) {
            toast.dismiss(); // Dismiss the loading toast
            toast.error('Error', {
              theme: 'colored',
              hideProgressBar: true,
              autoClose: 2000,
            });
          }
        } else {
            toast.dismiss(); // Dismiss the loading toast
            toast.error('Please ensure all fields are filled out correctly', {
              theme: 'colored',
              hideProgressBar: true,
              autoClose: 2000,
            });
        }
      };

    const GoBack = (e) => {
        e.preventDefault();
        navigate('/search')
    }

    const handleLogout = async () => {
        try {
          await logout();
          navigate('/');
        } catch (e) {
          console.log(e.message);
        }
      };
    

    return(
        <section className="bg-white max-w-3xl min-w-3x1 mx-auto shadow-md">
            <div className="max-w-2xl px-4 py-8 mx-auto lg:py-16">
                <h1 className="mb-4 text-3xl text-gray-90">Create new <span className="text-[#20aee2]">Candidate</span></h1>
                <form action="#" className="py-10">
                    <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">

                        <div className="sm:col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                            <input name="Email" type="email"  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" onChange={(e) => {handleChange(e);}} required />
                        </div>

                        <div className="w-full">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First name</label>
                            <input name="FirstName" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required  onChange={(e) => {handleChange(e);}}/>
                        </div>

                        <div className="w-full">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last name</label>
                            <input name="LastName" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required onChange={(e) => {handleChange(e);}}/>
                        </div>

                        <div className="sm:col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone number</label>
                            <input name="PhoneNumber" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required onChange={(e) => {handleChange(e);}} />
                        </div>

                        <div className="sm:col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Job title</label>
                            <input name="JobTitle" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required onChange={(e) => {handleChange(e);}} />
                        </div>

                        <div className="sm:col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Company name</label>
                            <input name="CompanyName" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"  required onChange={(e) => {handleChange(e);}} />
                        </div>

                    </div>

                    <div className="flex items-center space-x-4">
                    <button onClick={HandleClick} className="bg-green-400 hover:bg-emerald-400 transition text-white font-bold py-2 px-4 rounded inline-flex items-center">
                        <FaPlus className="fill-current w-4 h-4 mr-2" />
                        <span>Create</span> 
                    </button>
                    </div>
                </form>
            </div>
                <div className="items-right">
                    <button onClick={GoBack} className="w-1/4 float-right mt-5 mr-5  py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white  bg-[#1EADE1] hover:bg-[#1FAEF9]">Back</button>
                    <button type="submit" onClick={handleLogout} className="w-1/4 float-right mt-5 mr-5  py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white bg-[#F51E1E] hover:bg-[#F50501]">Logout</button>
                </div>
            </section>
    )
}

export default CandidateForm