import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCK7BJ8imjbldBx6oPhyj4fLfk-fNmPIWc",
  authDomain: "compass-gcp.firebaseapp.com",
  databaseURL: "https://compass-gcp-default-rtdb.firebaseio.com",
  projectId: "compass-gcp",
  storageBucket: "compass-gcp.appspot.com",
  messagingSenderId: "267434742395",
  appId: "1:267434742395:web:5cd044a1dd116dae2c48de"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Set Firebase Authentication to use "local" persistence with a 30-day expiration
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Local persistence set successfully.");
  })
  .catch((error) => {
    console.error("Error setting local persistence:", error);
  });

export { auth };