import React, { createContext, useState, useCallback } from "react"; // Import React
import axios from "axios";

const FetchUserContext = createContext();

function Provider({ children }) {
  
  //Prod
  const [API, setAPI] = useState("https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth");

  //Dev
  //const [API, setAPI] = useState("https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth");

  const [documents, setDocuments] = useState([]);
  const [inveniasdocuments, setInveniasdocuments] = useState();
  const [defaultInveniasdocuments, setDefaultInveniasdocuments] = useState();
  const [APIdata, setAPIdata] = useState('')
  const [CurrentPickedCan, setCurrentPickedCan] = useState("")
  const [currentDocument, setCurrentDocument] = useState("")

  const[currentUserID, setCurrentUserID] = useState("")

  const [lookedUser, SetLookedUser] = useState()
  const [lookedPhoneNumber, SetlookedPhoneNumber] = useState()


  const CurrentUser = (id) => {
      setCurrentUserID(id)
  }

  const CreateCandidate = async (value) => {
      console.log(value)
      
      const response = await axios.post(
        'https://europe-west3-compass-gcp.cloudfunctions.net/webhook-invenias-db',
        value
      );
  }

  const fetchEmail = async (value) => {
    try {
      const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchOne?email=${value}`);
      const data = response.data;
      setAPIdata(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserDocumentsGoogle = async (id) => { // Added useCallback for optimization
    try {
      const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_storage_no_auth/api/google/readfiles?UserID=${id}`);
      if (response.status === 200) {
        const data = response.data;
        setDocuments(data.fileIds);
      }
    } catch (error) {
      console.error("Error fetching user documents:", error);
    }
  }; // Added empty dependency array


  const lookUpUserByFullName = async (firstname, lastname) => {
    try {
      const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchUserByName?first_name=${firstname}&last_name=${lastname}`);
      const data = response.data;
      SetLookedUser(data);
    } catch (error) {
      console.log(error);
    }
  };

  const lookUpUserByName = async (name) => {
    try {
      const response = await axios.get(API + `/api/bigquery/lookUpUser?name=${name}`);
      const data = response.data;
      SetLookedUser(data);
    } catch (error) {
      console.log(error);
    }
  };

  const lookUpPhonenumber = async (phonenumber) => {
    try {
      const response = await axios.get(API + `/api/bigquery/lookUpNumber?phone_number=${phonenumber}`);
      const data = response.data;
      SetlookedPhoneNumber(data);
    } catch (error) {
      console.log(error);
    }
  };


  const lookUpInveniasFiles = async(id) => {
    try{
      const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_storage_no_auth/api/google/readfiles-list-invenias?id=${id}`);
      const data = response.data;
      setInveniasdocuments(data);
    } catch(error){
      console.log(error)
    }
  }

  const UpdateInveniasFiles = (newlist) =>{
    setInveniasdocuments(newlist)
  }


  const defaultLookUpInveniasFiles = async (id) => {
    try {
      const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_storage_no_auth/api/google/readfiles-list-invenias?id=${id}`);
      const data = response.data;
  
      let DefaultDoc = data.Items.find(item => item.IsDefaultCv === true);
  
      if (!DefaultDoc) {
        DefaultDoc = data.Items[0]; // Use the first item if none is marked as default
      }
  
      setCurrentDocument(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_storage_no_auth/api/google/readfile-invenias?id=${id}&file_id=${DefaultDoc.ItemId}&attachment_name=${DefaultDoc.AttachmentName}`);
      console.log(currentDocument);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchSingleInveniasDocument = async(id, fileid, filename) => {
    try{
      setCurrentDocument(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_storage_no_auth/api/google/readfile-invenias?id=${id}&file_id=${fileid}&attachment_name=${filename}`)
    } catch(error){
      console.log(error)
    }

  }




  const valueToShare = {
    currentUserID,
    CurrentUser,
    documents,
    fetchUserDocumentsGoogle,
    lookedUser,
    lookUpUserByName,
    inveniasdocuments,
    lookUpInveniasFiles,
    UpdateInveniasFiles,
    currentDocument,
    fetchSingleInveniasDocument,
    defaultInveniasdocuments,
    defaultLookUpInveniasFiles,
    lookUpPhonenumber,
    lookedPhoneNumber,
    CreateCandidate
  };

  

  return (
    <FetchUserContext.Provider value={valueToShare}>
      {children}
    </FetchUserContext.Provider>
  );
}

export { Provider };
export default FetchUserContext;