import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Logo from "../assets/images/2C360_Logo.png";
import { UserAuth } from '../contexts/AuthContext'; // Import UserAuth from your context

const LoginForm = () => {
  const navigate = useNavigate();

  const { user, signIn } = UserAuth(); // Access user state and signIn function from your context

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLoginFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    signIn(email, password)
      .then((userCredential) => {
        // User signed in successfully
        console.log('User signed in successfully');
        navigate('/search');
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Wrong email or password..", {
          theme: "colored",
          hideProgressBar: true
        });
      });
  };

  useEffect(() => {
    // Check if a user is already signed in when the component mounts
    if (user) {
      // User is signed in, navigate to a different page or perform other actions
      navigate('/search');
    }
  }, [user, navigate]);

  return (
    <div className="mt-8 flex flex-col max-w-xl mx-auto">
      <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10">
        <img className="mx-auto h-24 w-auto mb-2" src={Logo} alt="logo" />
        <form className="mb-0 space-y-6" onSubmit={handleLoginFormSubmit}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div className="mt-1">
              <input id="email" name="email" type="email" required className="w-full shadow border-2 rounded h-10 p-2.5" onChange={(e) => setEmail(e.target.value)} />
            </div>
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div className="mt-1">
              <input id="password" name="password" type="password" required className="w-full shadow border-2 rounded h-10 p-2.5" onChange={(e) => setPassword(e.target.value)} />
            </div>
          </div>
          <div className="flex justify-center">
            <button type="submit" disabled={loading} className="w-1/2 py-2 px-4 disabled:opacity-30 rounded-md shadow-sm text-sm font-medium text-white bg-[#1EADE1] hover:bg-[#1FAEF9]"> Log In </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;