import React, { useState, useRef, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const ElementMarker = (props) => {
  const [showInputEle, setShowInputEle] = useState(false);
  const [inputValue, setInputValue] = useState(props.value);
  const inputRef = useRef(null);

  useEffect(() => {
    if (showInputEle) {
      inputRef.current.focus();
    }
  }, [showInputEle]);

  const handleDoubleClick = () => {
    setShowInputEle(true);
  };

  const eleHandleChange = (e) => {
    setInputValue(e.target.value);
    setShowInputEle(true)
    props.onChange(e);
  };

  const handleBlur = () => {
    setShowInputEle(false);
  };

  const getCharacterWidth = (char) => {
    const characterAdjustments = {
      '.': 0.2, // Add more characters as needed
      ',': 0.2,
      '-': 0.2,
    };
    return characterAdjustments[char] || (/\d/.test(char) ? 1.2 : 1.04); // Adjustments for numbers and letters
  };

  let totalWidth = 0;


  if(inputValue){

    for (let i = 0; i < inputValue.length; i++) {
        totalWidth += getCharacterWidth(inputValue[i]);
    }

  }

  const inputWidth = `${totalWidth}ch`;


  return (
    <Tippy content="Double Click To Edit" arrow={false}>
         
      {/*
        <input type="text" value={inputValue} name={props.name} onChange={(e) => eleHandleChange(e)} onBlur={handleBlur} ref={inputRef} className="h-6 px-0 py-0 border-2 border-dashed outline-none border-sky-300" style={{ width: inputWidth, minWidth: '50px'}}/>
        
      */}
      <span>
        {
          showInputEle ? (
              <input type="text" value={inputValue} name={props.name} onChange={(e) => eleHandleChange(e)} onBlur={handleBlur} ref={inputRef} className="h-6 px-0 py-0 border-2 border-dashed outline-none border-sky-300" style={{ width: inputWidth, minWidth: '50px'}}/>
          ) : 
              inputValue ?
              <span onDoubleClick={handleDoubleClick} className="inline-block px-2" style={{ width: inputWidth, minWidth: '50px'}}>
                {inputValue}
              </span>
              :
              <input type="text" value={inputValue} name={props.name} onChange={(e) => eleHandleChange(e)} onBlur={handleBlur} ref={inputRef} className="h-6 px-0 py-0 border-2 border-dashed outline-none border-sky-300" style={{ width: inputWidth, minWidth: '50px'}}/>
            }
      </span>
    </Tippy>
  );
};

export default ElementMarker;