import React, { useState, useEffect } from "react";


const PeoplePagination = (props) => {

    const showMaxIndex = props.currentPageIndex + 5
    const showMinIndex = props.currentPageIndex - 5

    
    const renderListItems = () => {

        const listItems = []

        for(let i=1; i <= props.totalPages; i++){

            i === 1 ? 
                i === props.currentPageIndex ?
                
                listItems.push(<li onClick={props.changePageIndex}>
                                    <a index={i} href="#" aria-current="page" class="flex items-center justify-center px-4 h-10 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">{i}</a>
                                </li>  )
                :
                listItems.push(<li onClick={props.changePageIndex}>
                                    <a index={i} href="#" class="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{i}</a>
                                </li>  )
            :
            i > showMinIndex && i < showMaxIndex  ?

            //console.log("show list items")
                i === props.currentPageIndex ?
            
                listItems.push(<li onClick={props.changePageIndex}>
                                    <a index={i} href="#" aria-current="page" class="flex items-center justify-center px-4 h-10 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">{i}</a>
                                </li>  )
                :
                listItems.push(<li onClick={props.changePageIndex}>
                                    <a index={i} href="#" class="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{i}</a>
                                </li>  )
            :
            //console.log("don't show")

                i === showMaxIndex || i === showMinIndex ?
                    listItems.push(<li>
                        <a href="#" class="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">...</a>
                    </li>  )
                : 

                    i === props.totalPages ?
                        listItems.push(<li onClick={props.changePageIndex}>
                            <a index={i} href="#" class="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{i}</a>
                        </li>  )
                    : 
                        console.log()
            
        }

        return listItems
        
    }

    return(
        <nav aria-label="Page navigation example" class="people-results-pagination">
            <ul class="inline-flex -space-x-px text-base h-10">

                <li onClick={props.changePageIndex}>
                    <a index={props.currentPageIndex - 1} href="#" class="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</a>
                </li>
                
                    {renderListItems()}
                    
                <li onClick={props.changePageIndex}>
                    < a index={props.currentPageIndex + 1} href="#" class="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
                </li>
                
            </ul>
        </nav>
    )
}

export {PeoplePagination} ;