import React, { useEffect, useState } from "react";
import useFetchUserContext from "../hooks/use-userInformation-context";
import { FiPaperclip, FiStar } from "react-icons/fi";

function DocumentHandler({PickCurrentPDF, UpdateDocuments}) {
  const { fetchUserDocumentsGoogle, documents, lookUpInveniasFiles, inveniasdocuments, currentUserID, UpdateInveniasFiles } = useFetchUserContext();
  const [updatedDocs, setUpdatedDocs] = useState([]);

  useEffect(() => {
    lookUpInveniasFiles(currentUserID);
  }, [currentUserID]);

  useEffect(() => {
    setUpdatedDocs(inveniasdocuments);
  }, [inveniasdocuments]);
  


  const handleStarClick = (idArray) => {
    
    // Assuming idArray is an array with a single string element
    const id = idArray[0];
  
    const Items = [...inveniasdocuments.Items];
    const index = Items.findIndex(item => item.ItemId === id);

    if (index !== -1) {
      Items[index].IsDefaultCv = true;
      Items.forEach(item => {
        if (item.ItemId !== id) {
          item.IsDefaultCv = false;
        }
      });
      setUpdatedDocs({Items});
      UpdateDocuments({Items});
    }
  }

  return (
    <div className="bg-white grid-cols-12">
      <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
        {updatedDocs?.Items && updatedDocs.Items.map((item, index) => (
          <li key={index} className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
            <span className="ml-2 w-0 flex-1 truncate">
              {item.AttachmentName}
            </span>
            <button value={item} onClick={() => PickCurrentPDF([item.AttachmentName, item.ItemId])}>
                <FiPaperclip className="h-5 w-5 mr-5 text-[#1eade1]" />
            </button>

            <button value={item} onClick={() => handleStarClick([item.ItemId])}>
                <FiStar className="h-5 w-5 mr-5 text-[#1eade1]" style={{ color: item.IsDefaultCv ? '#FFD700' : '#d0d6d8' }} />
            </button>

          </li>
        ))}
      </ul>
    </div>
  );
}

export default DocumentHandler;