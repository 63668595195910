import React, { useState, useEffect, useRef} from 'react';
import { useNavigate, useHistory } from 'react-router-dom'
import useFetchUserContext from "../../hooks/use-userInformation-context";

function AutoComplete() {
  const navigate = useNavigate();
  const { lookUpUserByName, lookedUser, lookUpPhonenumber, lookedPhoneNumber } = useFetchUserContext();

  const [searchResults, setSearchResults] = useState({});
  const [currentUser, setCurrentUser] = useState('');
  const [currentSearchMethode, setCurrentSearchMethode] = useState(1);


  const [isInputFocusedClick, setInputFocusedClick] = useState(false);
  const dropdownRef = useRef(null);

  const OnCurrentValue = (e) => {
    if(currentSearchMethode === 1){
      lookUpUserByName(e.target.value)
      setSearchResults(lookedUser)
    }
    else if (currentSearchMethode === 2){
      setSearchResults("");
      setCurrentUser("");

    }
    else if(currentSearchMethode === 3){
      lookUpPhonenumber(e.target.value)
      setSearchResults(lookedPhoneNumber)
    }

    setInputFocusedClick(true);
    const inputValue = e.target.value;
    setCurrentUser(inputValue);
    console.log(searchResults)
  };

  const handleClick = (result) => {
    setSearchResults(result);
    setInputFocusedClick(false);
    setCurrentUser(result.full_name)
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setInputFocusedClick(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  const handleSelectChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setCurrentSearchMethode(selectedValue);
    setSearchResults('');
    setCurrentUser("");
  };

  const OnSearchClick = () =>{
    navigate('/results');
  }


  return (
    <div>
        <div className="relative w-full mb-5">
            <select className="w-full text-gray-700 bg-white border rounded shadow h-10 pl-2.5" onChange={handleSelectChange}>
              <option value={1}>Name</option>
              <option value={2}>Email</option>
              <option value={3}>Phone number</option>
            </select>
        </div>

        <div className="grid grid-cols-4">
          <div className="col-span-3">
            <input value={currentUser} type="text"  onChange={(e) => OnCurrentValue(e)} className="w-full border rounded h-10 p-2.5 rounded-tr-none rounded-br-none" placeholder="Type Here..."/>
          </div>

          <div className="col-span-1">
            <button type="submit" onClick={OnSearchClick} className="w-full button-custom px-4 rounded-md rounded-tl-none rounded-bl-none shadow-sm text-sm font-medium text-white bg-[#1EADE1] hover:bg-[#1FAEF9]">
              Search
            </button>
          </div>
            
          <div className="relative h-2 col-span-3">
            <ul className="bg-white border-r-2 border-b-2 border-l-2 border-gray-200" ref={dropdownRef}>
                {Array.isArray(searchResults) && isInputFocusedClick && searchResults.map((result, index) => (
                    <li className='p-2' key={index}   onClick={() => handleClick(result)}>
                      {currentSearchMethode === 1 && result.full_name}
                      {currentSearchMethode === 3 && result.phone_number}
                    </li>
                ))}
            </ul>
          </div>
        </div>
    </div>
  );
}

export default AutoComplete;
